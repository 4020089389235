.search {
  margin: 0;
  position: relative;

  input {
    font-family: inherit;
    color: $neutral;
    padding: 8px 31px 8px 15px;
    font-size: $text-lg;
    width: 100%;

    background-image: svg-load('light/magnifying-glass.svg', fill=$neutral-40);
    background-size: 18px auto;
    background-position: right 8px center;
    background-repeat: no-repeat;

    ::placeholder {
      color: $neutral-60;
    }
  }

  .results {
    background: $white;
    max-width: 100%;
    box-sizing: border-box;
    display: none;
    text-align: left;

    &.visible {
      display: block;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      &:empty {
        display: none;
      }
    }

    li {
      &:hover {
        background-color: $neutral-5;
      }
    }

    a {
      display: block;
      border: none;

      transition-property: background-color;
      transition-duration: .1s;
      transition-timing-function: ease-in-out;

      padding: 10px;


      .title {
        color: $primary;
        font-size: $text-lg;
      }

      .description {
        color: $neutral-60;
        font-size: $text-base;

        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    mark {
      background-color: $primary-pastel;
    }
  }
}

@import 'config';
@import 'mixins';

@import 'components/header';
@import 'components/footer';
@import 'components/menu';
@import 'components/button';
@import 'components/figure';
@import 'components/section';
@import 'components/pagination';
@import 'components/input';
@import 'components/form';
@import 'components/video';
@import 'components/avatar';
@import 'components/notification';
@import 'components/cookie-consent';
@import 'components/prose';
@import 'components/transitions';
@import 'components/syntax';
@import 'components/breadcrumbs';
@import 'components/card';
@import 'components/sidebar';
@import 'components/search';
@import 'components/badge';
@import 'components/scroller';
@import 'components/lead-magnet';
@import 'components/code';

@import 'sections/hero';
@import 'sections/landing-page-hero';
@import 'sections/podcast';
@import 'sections/service';
@import 'sections/testimonial';
@import 'sections/features-grid';
@import 'sections/features-alternating';
@import 'sections/features-mini';
@import 'sections/comparison-table';
@import 'sections/call-to-action';
@import 'sections/lead-magnet';
@import 'sections/lead-magnet-hero';
@import 'sections/image';
@import 'sections/logos';
@import 'sections/screenshots';
@import 'sections/feature';
@import 'sections/video-message';
@import 'sections/content-grid';
@import 'sections/checklist';
@import 'sections/pricing';
@import 'sections/articles';
@import 'sections/article';
@import 'sections/more-articles';
@import 'sections/updates';
@import 'sections/gallery';
@import 'sections/podcasts';
@import 'sections/customers';
@import 'sections/founders';
@import 'sections/team';
@import 'sections/documentation';
@import 'sections/divider';
@import 'sections/simple-copy';
@import 'sections/episodes';
@import 'sections/testimonials';
@import 'sections/savvycal';
@import 'sections/cards';
@import 'sections/integrations';
@import 'sections/integration-showcase';
@import 'sections/resource';

@import 'layouts/minimal';

html {
  font-family: $font-primary;
  font-style: normal;
  font-size: 14px;
  letter-spacing: 0.01em;

  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  scroll-behavior: smooth;

  @media screen and (prefers-reduced-motion: reduce) {
    scroll-behavior: auto;
  }
}


body {
  margin: 0;
  padding: 0;
  color: $neutral-80;

  background-color: $white;

  &.dark {
    background-color: $neutral;
  }

  &.light-blue {
    background-color: $light-blue;
  }

  &.centered {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;

    > * {
      flex: 1;
    }
  }

  &.docs {
    > header {
      position: unset;
      box-shadow: none;
    }

    > main {
      margin-top: 0;
    }
  }

  &.landing-page,
  &.lead-magnet {
    > header,
    > footer {
      display: none;
    }

    > main {
      margin-top: 0;
    }
  }
}

.container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 6%;
}


h1, h2, h3, h4, h5, h6 {
  margin: 0;
  color: $neutral;

  > a {
    text-decoration: none;
    color: inherit;
    border: none;
    color: inherit;
  }
}

h1, h2, h3 {
  font-weight: normal;
}

h4, h5 {
  line-height: 1.5;
}

h5, h6 {
  text-transform: uppercase;
}

h1 {
  font-size: $text-7xl;
  line-height: 1;
  letter-spacing: -0.02em;

  @include respond-to('mobile') {
    font-size: rem(40px);
  }
}

h2 {
  font-size: $text-5xl;
  line-height: 1.2;
  letter-spacing: -0.01em;
}

h3 {
  font-size: $text-2xl;
  line-height: 1.3;
  letter-spacing: -0.01em;
}

h4 {
  font-size: $text-xl;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 1.4;
}

h5 {
  font-weight: 300;
  font-size: $text-sm;
  letter-spacing: 0.01em;
}

h6 {
  color: $neutral-40;
  font-family: $font-secondary;
  font-size: $text-sm;
  letter-spacing: 3px;
  line-height: 1.75;
  font-weight: normal;
}

a {
  border-bottom: 1px dashed currentColor;
  transition: .2s ease-in-out;
  transition-property: color, border-color;
  text-decoration: none;
  color: $primary;

  will-change: border-color, color;

  &:hover {
    color: $primary-lighter;
  }

  &.read_more {
    border-bottom: none;
    color: $neutral-60;
    font-family: $font-secondary;
    letter-spacing: -0.01em;

    svg {
      font-size: $text-xl;
      margin-right: .6em;
      vertical-align: -.25em;
    }
  }
}

.icon {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  width: 1em;
  color: inherit;
  overflow: visible;
  vertical-align: -.125em;

  path, use {
    fill: currentColor;
  }
}

svg.logo {
  color: inherit;
  fill: currentColor;
}

body > header,
body > footer {
  a {
    border-bottom: none;
    color: inherit;
  }
}

p {
  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.results {
  padding: 20px;

  border-radius: 5px;
  box-shadow: 0px 16px 64px rgba(0, 0, 0, 0.1);

  background: $white;
  max-width: 100%;
  box-sizing: border-box;

  position: absolute;
  z-index: 10000;
  top: 50px;
}

@mixin grid($columns, $gap: 40px) {
  display: grid;
  grid-gap: $gap;

  @include respond-to('tablet') {
    grid-template-columns: repeat($columns, 1fr);
  }
}

$breakpoints: () !default;

@mixin respond-to($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  }

  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

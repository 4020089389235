section.documentation {
  .sidebar {
    display: none;
  }

  article {
    padding-bottom: 120px;
    max-width: 650px;

    .breadcrumbs {
      margin-bottom: 45px;
    }

    h1 {
      margin-bottom: 40px;
      font-size: rem(44px);
    }

    footer {
      border-top: solid 1px $neutral-15;
      padding: 20px 0;
      margin-top: 45px;
      font-size: $text-base;

      display: flex;
      flex-direction: row;
      align-items: flex-start;

      .icon {
        color: $neutral-40;
        font-size: $text-xl;
        margin-right: 0.5em;
        margin-top: 0.1em;
      }
    }

    body.docs_index & {
      .breadcrumbs {
        visibility: hidden;
        margin-bottom: 0px;
      }

      footer {
        display: none;
      }
    }
  }

  @include respond-to('desktop') {
    .container {
      display: flex;
      flex-direction: row;
    }

    .sidebar {
      box-sizing: border-box;
      display: block;
      flex: 0 0 250px;
      min-width: 290px;
      padding-right: 40px;
    }

    article {
      max-width: 650px;
      width: 100%;

      background-color: white;
      z-index: 1000;
      margin-top: -116px;

      padding: 36px 0 120px 80px;

      flex: 1;
    }
  }
}

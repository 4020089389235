section.features-grid {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding-top: 100px;
    padding-bottom: 120px;
  }

  h2 {
    text-align: center;
    margin-bottom: 80px;
  }

  .features {
    @include grid(3, 70px);
  }

  &.two-columns {
    .features {
      @include grid(2, 50px);
    }
  }

  &.four-columns {
    .features {
      @include grid(4, 50px);
    }
  }

  .feature {
    display: flex;

    header {
      @extend h4;
      margin-bottom: 10px !important;
    }

    .icon {
      font-size: 1.8rem;
      color: $neutral;
      width: 40px;
      text-align: center;

      .icon {
        vertical-align: top;
      }
    }

    .content {
      margin-left: 10px;
      flex: 1;
    }

    .description {
      color: $neutral-80;
    }
  }

  .icon {
    color: $neutral-40;
  }
}

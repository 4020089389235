section.cards {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;

    text-align: center;

    padding-top: 100px;
    padding-bottom: 120px;
  }

  h2 {
    margin-bottom: 20px;
  }

  .description {
    font-size: $text-xl;
    font-weight: normal;

    margin: 0 0 60px;

    max-width: 640px;
  }

  .contents {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
  }

  .large-card {
    border-radius: 20px;

    flex: 1;
    flex-direction: row;
    align-items: center;;

    padding: 60px;

    img {
      margin-bottom: 60px;
      border: 0;

      width: 200px;
      aspect-ratio: 1;
    }

    .tagline {
      text-transform: uppercase;
      color: $neutral-60;
      font-weight: normal;
      font-style: normal;
      font-size: $text-sm;
      font-family: $font-secondary;
      line-height: 1em;
      letter-spacing: 3px;
    }

    header {
      font-size: $text-3xl;
      line-height: 1.5;
      margin: 10px;
    }

    .description {
      margin-bottom: 40px;
      font-size: $text-base;
    }

    &.dark {
      background-color: black;

      header {
        color: $white;
      }

      .description {
        color: $neutral-40;
      }
    }

    &.light {
      background-color: $neutral-5;

      header {
        color: black;
      }

      .description {
        color: $neutral-80;
      }
    }
  }
}

.prose {
  font-size: $text-lg;
  line-height: 1.60;
  color: $neutral;


  h1 {
    font-size: rem(50px);
    line-height: 1.2;
    letter-spacing: -0.02em;
  }

  h2 {
    font-size: rem(28px);
    line-height: 1.2;
    letter-spacing: -0.01em;
    margin-top: 40px;
  }

  h3, h4 {
    line-height: 1.3;
    letter-spacing: -0.01em;
    margin-top: 35px;
  }

  h3 {
    font-size: rem(22px);
  }

  h4 {
    font-size: rem(18px);
  }

  img, figure {
    margin: 25px 0;
  }

  figure {
    img {
      margin: 0;
    }
  }

  img, video {
    max-width: 100%;

    &.screenshot {
      box-shadow: rgba(0, 0, 0, 0.2) 2px 4px 10px 0px;
      border-radius: 4px;
      overflow: hidden;
    }
  }

  figure.screenshot img {
    box-shadow: rgba(0, 0, 0, 0.2) 2px 4px 10px 0px;
    border-radius: 4px;
    overflow: hidden;
  }

  blockquote {
    background-color: $neutral-5;
    padding: 2em;
    margin: 40px 0;
    font-size: $text-xl;

    border-left: solid 5px $neutral-15;
  }

  .callout {
    background-color: $neutral-5;
    padding: 2em;
    margin: 40px 0;
    font-size: $text-base;

    border-left: solid 5px $neutral-15;

    &.info {
      border-left-color: $primary;
      background-color: $primary-pastel;
    }

    &.warning {
      border-left-color: $red;
    }

    ul {
      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  > table {
    width: 100%;
    border-collapse: collapse;
    margin: 40px 0;
    font-size: $text-base;

    th, td {
      padding: .5em 1em;
      border-bottom: solid 1px $neutral-15;
    }

    th {
      font-weight: normal;
      text-align: left;
      color: $neutral-60;
    }
  }

  code {
    display: inline-block;
    padding: 0 .3em;
    border: solid 1px $neutral-15;
    border-radius: 4px;
    font-size: .9em;
  }


  div.highlight {
    width: 100%;
    border-radius: 4px;
    padding: 0.5em;
    margin: 40px 0;
    max-width: 100%;
    overflow-x: auto;
    -webkit-text-size-adjust: 100%;
    font-size: $text-base;
    box-sizing: border-box;

    code {
      display: block;
      padding: 0;
      border: none;
      border-radius: 0;
      font-size: inherit;
    }

    pre {
      margin: 0;
    }

    .gl {
      background: transparent;
      opacity: 0.5;
    }
  }

  iframe {
    box-shadow: rgba(0, 0, 0, 0.2) 2px 4px 10px 0px;
    border-radius: 4px;
    overflow: hidden;
    z-index: 1;
    overflow: hidden;
  }

  // Cards
  .card, .grid {
    margin: 40px 0 60px;

    + .card, + .grid {
      margin-top: -20px;
    }
  }

  .grid {
    @include respond-to('tablet') {
      @include grid(2, 30px);

      .card {
        margin: 0;
      }
    }
  }

  // Special list styles
  ul, ol {
    margin: 16px 0;

    li {
      padding: 3px 0;
    }

    &.steps {
      list-style: none;
      counter-reset: steps-counter;
      padding-left: 55px;

      li {
        counter-increment: steps-counter;
        position: relative;
        padding-top: .4em;
        padding-bottom: 1em;

        &:before {
          content: counter(steps-counter);
          position: absolute;
          top: 0;
          left: -55px;

          color: $primary;
          background-color: $primary-pastel;

          width: 40px;
          height: 40px;

          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100%;
        }
      }
    }
  }

  // Inline navigation
  nav {
    margin-bottom: 40px;
    margin-top: 40px;

    header {
      @extend h6;

      margin-bottom: 20px !important;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    li {
      padding: 4px 0;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }

    a {
      position: relative;

      &[href^="/docs/"]:before {
        content: svg-load('light/file-lines.svg', fill=$neutral-40);
        display: inline-block;
        margin-right: 0.5em;
        width: $text-base;
        top: .125em;
        left: -1.5em;
        position: absolute;
      }
    }

    &.table-of-contents {
      font-size: $text-base;
    }

    &.related-articles {
      ul {
        margin-left: 1.5em;
      }
    }
  }
}

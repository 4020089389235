body {
  margin-top: 70px;

  * {
    scroll-margin-top: 130px;
  }


  > header {
    box-shadow: 0 0 clamp(0px, calc(var(--scroll-y, 0) / 100), 10px) rgba(black, 0.1);
    background: white;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;

    nav {
      color: $neutral;

      .container {
        display: flex;
        align-items: center;
        flex-direction: row;

        padding: clamp(0px, calc(15px - var(--scroll-y, 0)), 15px) 6%;

        .icon {
          color: inherit;
          font-size: 1.33333em !important;
          vertical-align: -.225em !important;
          line-height: .75em !important;
        }
      }

      a {
        color: inherit;
        display: inline-block;

        &.button {
          font-size: inherit;
          padding: 0.9em 1.8em;
        }
      }

      .brand {
        margin-left: -11px;

        a {
          padding: 11px;

          color: inherit;

          &:hover {
            color: inherit;
          }
        }
      }

      ul {
        list-style: none;
        padding: 0;

        a {
          padding: 11px;
        }
      }

      .container > ul {
        margin: 0 -11px 0 auto;

        li:last-child a:not(.button) {
          padding-right: 0;
        }
      }

      &.desktop {
        ul > li {
          margin: 0 3px 0 0;
          display: inline-block;

          &:last-child {
            margin: 0 0 0 8px;
          }

          .icon {
            color: inherit;

            &:first-child {
              margin-right: 10px;
            }

            &:last-child {
              margin-left: 10px;
            }

            &.icon-angle-down {
              margin-left: 2px;
            }
          }
        }

        .menu {
          display: grid;

          &.one-column {
            width: 355px;
            grid-template-columns: repeat(1, 1fr);
          }

          &.two-columns {
            width: 670px;
            grid-template-columns: repeat(2, 1fr);
          }

          .item {
            display: block;
            padding: 20px;
            color: $neutral;

            transition-duration: .2s;
            transition-timing-function: ease-in-out;
            transition-property: background-color;

            will-change: background-color;

            &:hover {
              background: $neutral-5;
            }

            display: flex;

            header {
              display: inline-block;
              margin-bottom: 6px;
            }

            .content {
              margin-left: 15px;
              font-size: $text-lg;
            }

            .description {
              color: $neutral-80;
              font-weight: normal;
              font-size: $text-little;
            }

            .icon {
              color: $neutral;
              font-size: 1.6em;
            }
          }
        }
      }

      &.mobile {
        .toggle {
          display: inline-block;
          padding: 11px;
          font-size: 18px;
          cursor: pointer;

          &[aria-expanded=true] {
            .closed { display: none; }
            .opened { display: inline-block; }
          }

          &[aria-expanded=false] {
            .closed { display: inline-block; }
            .opened { display: none; }
          }
        }

        &[data-expanded=true] {
          .toggle[aria-expanded=false] {
            display: none;
          }
        }
        .search {
          .results ul {
            margin-top: 20px;
          }
        }

        .menu {
          text-align: center;
          font-size: $text-lg;

          a {
            display: block;
            padding: 8px;

            &[aria-controls] {
              .icon-angle-down {
                display: inline-block;

                margin-left: 5px;
                margin-right: -24px;

                transition: transform .1s ease-in-out;
                transform-origin: center;
                transform: rotate(-90deg);

                will-change: transform;
              }
            }

            &[aria-expanded=true] {
              .icon-angle-down {
                transform: rotate(0);
              }
            }
          }

          .navigation {
            margin-bottom: 40px;

            .icon {
              display: none;
            }

            ul {
              font-size: $text-base;
              margin: 10px 0;

              display: none;

              &.visible {
                display: block;
              }

              a {
                color: $neutral-80;
                padding: 4px;

                &:hover {
                  color: $primary-lighter;
                }

                &[aria-current=page] {
                  font-weight: 600;
                }
              }
            }
          }
        }
      }

      @include respond-to('mobile') {
        &.mobile {
          display: block;
        }

        &.desktop {
          display: none;
        }
      }

      @include respond-to('tablet') {
        &.mobile {
          display: block;
        }

        &.desktop {
          display: none;
        }
      }

      @include respond-to('desktop') {
        &.desktop {
          display: block;
        }

        &.mobile {
          display: none;
        }
      }
    }
  }

  > header + main {
    margin-top: 95px;
  }

  &.podcast > header {
    background-color: black;

    nav {
      color: $white;
    }
  }
}
